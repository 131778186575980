import React, { ComponentProps, FC, PropsWithChildren, ReactNode } from 'react'
import useModal from 'hooks/useModal'
import { Button, ConfigProvider, Modal } from 'antd'

type Props = {
  buttonLabel: string
  buttonIcon: ReactNode
  disabled?: boolean
} & Omit<ComponentProps<typeof Modal>, 'open' | 'onCancel'> &
  PropsWithChildren

const CardMenuModal: FC<Props> = ({ children, buttonLabel, buttonIcon, okButtonProps, disabled = false, ...rest }) => {
  const { isVisible, openModal, closeModal } = useModal()

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            textHoverBg: 'rgba(0, 0, 0, 0)'
          }
        }
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <Button
          icon={buttonIcon}
          style={{ width: '100%', textAlign: 'left' }}
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            openModal()
          }}
          className="justify-start"
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
        <Modal
          destroyOnClose
          open={isVisible}
          onCancel={(e) => {
            e.stopPropagation()
            closeModal()
          }}
          wrapClassName="lg:h-screen h-dvh"
          className="lg:h-screen h-dvh !max-w-[calc(100vw-32px)] top-4 md:top-[1.75vh][&_.ant-modal-content]:max-md:overflow-auto"
          cancelButtonProps={{ size: 'large', disabled: !!okButtonProps?.loading }}
          okButtonProps={{ htmlType: 'submit', size: 'large', onClick: (e) => e.stopPropagation(), ...okButtonProps }}
          {...rest}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child as React.ReactElement<any>, { onSubmit: closeModal })
          )}
        </Modal>
      </div>
    </ConfigProvider>
  )
}

export default CardMenuModal
