import { useState } from 'react'
import { Typography } from 'antd'

const ExpandableText = ({ text, limit = 200 }: { text: string; limit?: number }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => setExpanded(!expanded)

  return (
    <Typography.Paragraph
      className={`text-sm text-grayText ${text.length > limit ? 'cursor-pointer' : ''}`}
      onClick={toggleExpand}
    >
      {text.length > limit && !expanded ? `${text.slice(0, limit)}...` : text}
    </Typography.Paragraph>
  )
}

export default ExpandableText
