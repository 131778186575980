// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@aws-amplify/ui-react/styles.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import 'prismjs/themes/prism-tomorrow.css'
import { store } from './app/store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { settings } from './config/settings'
import { authStorage } from './app/auth-storage'
import { CustomThemeProvider } from 'components/ThemeContext'

if (settings.environment === 'production') {
  // Hotjar
  ;(function (h: any, o, t, j) {
    h.hj =
      h.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 5230207, hjsv: 6 }
    const a = o.getElementsByTagName('head')[0]
    const r: any = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

  // Heap
  ;((window as any).heapReadyCb = (window as any).heapReadyCb || []),
    ((window as any).heap = (window as any).heap || []),
    (heap.load = function (e, t) {
      ;(window.heap.envId = e),
        (window.heap.clientConfig = t = t || {}),
        (window.heap.clientConfig.shouldFetchServerConfig = !1)
      const a = document.createElement('script')
      ;(a.type = 'text/javascript'),
        (a.async = !0),
        (a.src = 'https://cdn.us.heap-api.com/config/' + e + '/heap_config.js')
      const r = document.getElementsByTagName('script')[0]
      r.parentNode.insertBefore(a, r)
      const n = [
          'init',
          'startTracking',
          'stopTracking',
          'track',
          'resetIdentity',
          'identify',
          'getSessionId',
          'getUserId',
          'getIdentity',
          'addUserProperties',
          'addEventProperties',
          'removeEventProperty',
          'clearEventProperties',
          'addAccountProperties',
          'addAdapter',
          'addTransformer',
          'addTransformerFn',
          'onReady',
          'addPageviewProperties',
          'removePageviewProperty',
          'clearPageviewProperties',
          'trackPageview'
        ],
        i = function (e) {
          return function () {
            // eslint-disable-next-line prefer-rest-params
            const t = Array.prototype.slice.call(arguments, 0)
            window.heapReadyCb.push({
              name: e,
              fn: function () {
                // eslint-disable-next-line
                heap[e] && heap[e].apply(heap, t)
              }
            })
          }
        }
      for (let p = 0; p < n.length; p++) heap[n[p]] = i(n[p])
    })
  heap.load('1190773427')

  if (!!authStorage.user) {
    heap.identify(authStorage.user.id)
    heap.addUserProperties({
      Name: `${authStorage.user.firstName} ${authStorage.user.lastName}`,
      email: authStorage.user.email
    })
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomThemeProvider>
        <App />
      </CustomThemeProvider>
      <ToastContainer autoClose={4000} pauseOnHover />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
