import { useGetWorkspacesOptionsQuery } from 'api/workspaces-api'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from 'components/ThemeContext'

export const useWorkspace = () => {
  const param = useParams()
  const { setThemeColors } = useTheme()
  const { data, isLoading } = useGetWorkspacesOptionsQuery()
  const currentWorkspace = param.workspaceId ? data?.find((item) => item.id === Number(param.workspaceId)) : undefined

  useEffect(() => {
    if (currentWorkspace && currentWorkspace.colors && currentWorkspace.currentMember) {
      setThemeColors(currentWorkspace?.colors)
    }
  }, [currentWorkspace])

  return {
    currentWorkspace,
    currentWorkspaceId: param.workspaceId ? Number(param.workspaceId) : undefined,
    workspaces: data,
    isLoading
  }
}
