import Markdown from 'react-markdown'
import Prism from 'prismjs'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC, useMemo, useState } from 'react'

type Props = {
  content: string
}

const CHARACTER_LIMIT = 320

const MarkdownMessage: FC<Props> = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const shouldShowExpandButton = content.length > CHARACTER_LIMIT

  const memoizedContent = useMemo(() => {
    if (!shouldShowExpandButton) return content
    return isExpanded ? content : content.slice(0, CHARACTER_LIMIT) + '...'
  }, [content, isExpanded, shouldShowExpandButton])

  return (
    <div className="flex flex-col gap-2">
      <Markdown
        skipHtml={false}
        className="flex flex-col gap-2.5"
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          ),
          code({ node, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
              <pre className={className}>
                <code
                  className={`language-${match[1]}`}
                  dangerouslySetInnerHTML={{
                    __html: Prism.highlight(String(children), Prism.languages['javascript'], 'javascript')
                  }}
                />
              </pre>
            ) : null
          }
        }}
      >
        {memoizedContent}
      </Markdown>
      {shouldShowExpandButton && (
        <Button
          type="link"
          className="p-0 h-auto text-xs text-primary self-start hover:text-primary/80"
          icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  )
}

export default MarkdownMessage
