import { lazy, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { AppRoutes } from 'app/app-routes'
import {
  AnonymousRoute,
  AppLayout,
  DataRoute,
  PrivateRoute,
  SettingsLayout,
  ThemeProvider,
  UserAccessRoute
} from 'components'
import { settings } from 'config/settings'
import { Flex, Spin } from 'antd'
import { Pto } from '@outcoreaix/pto'
import { Authenticator } from '@aws-amplify/ui-react'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: settings.aws.cognito.userPoolId,
      userPoolClientId: settings.aws.cognito.userPoolClientId,
      loginWith: {
        email: true,
        oauth: {
          domain: settings.aws.cognito.userPoolEndpoint,
          scopes: ['openid', 'email', 'phone'],
          responseType: 'code',
          redirectSignIn: [settings.aws.cognito.redirectUrl],
          redirectSignOut: [settings.aws.cognito.redirectUrl]
        }
      }
    }
  }
})

const Login = lazy(() => import('features/authorization/pages/Login'))
const OneTimeLink = lazy(() => import('features/authorization/pages/OneTimeLink'))
const OneTimeLinkAuth = lazy(() => import('features/authorization/pages/OneTimeLinkAuth'))
const AlertList = lazy(() => import('features/alerts/pages/AlertList'))
const AppSettings = lazy(() => import('features/app-settings/pages/AppSettings'))
const Workspaces = lazy(() => import('features/workspaces/pages/Workspaces'))
const WorkspaceSetup = lazy(() => import('features/workspaces/pages/WorkspaceSetup'))
const ProfileSettings = lazy(() => import('features/users/pages/ProfileSettings'))
const Support = lazy(() => import('features/app-settings/pages/Support'))
const UserInvite = lazy(() => import('features/users/pages/UserInvite'))
const UserFeedbacks = lazy(() => import('features/users/pages/UserFeedbacks'))
const Main = lazy(() => import('features/users/pages/Main/Main'))
const SpaceSetup = lazy(() => import('features/spaces/pages/SpaceSetup'))
const SpaceGeneralSettings = lazy(() => import('features/spaces/pages/SpaceGeneralSettings'))
const SpaceAIConfig = lazy(() => import('features/spaces/pages/SpaceAIConfig'))
const SpaceOnboardingInterview = lazy(() => import('features/spaces/pages/SpaceOnboardingInterview'))
const SpaceMembers = lazy(() => import('features/spaces/pages/SpaceMembers'))
const SpaceMemberProfile = lazy(() => import('features/spaces/pages/SpaceMemberProfile'))
const ConversationStartersManagement = lazy(
  () => import('features/conversationStartersManagement/pages/conversationStartersManagement')
)
const SystemAdminDashboard = lazy(() => import('features/systemAdminDashboard/pages/systemAdminDashboard'))
const ProductManagement = lazy(() => import('features/productManagement/pages/productManagement'))
const ProductSetUp = lazy(() => import('features/productManagement/pages/productSetUp'))
const SystemAdminSpaceManagement = lazy(
  () => import('features/systemAdminSpaceManagement/pages/systemAdminSpaceManagement')
)
const Flows = lazy(() => import('features/app-flows/pages/Flows'))
const FlowInfo = lazy(() => import('features/app-flows/pages/FlowInfo'))
const InductionCategories = lazy(() => import('features/app-flows/pages/Categories'))

const WorkspaceGeneralSettings = lazy(() => import('features/workspaces/pages/WorkspaceGeneralSettings'))
const WorkspaceMembersSettings = lazy(() => import('features/workspaces/pages/WorkspaceMembersSettings'))

const LearnerOnboardingPage = lazy(() => import('features/spaces/pages/LearnerOnboarding'))
const SpacePage = lazy(() => import('features/spaces/pages/Space'))

const NotFound = lazy(() => import('not-found'))
const ErrorPage = lazy(() => import('error-page'))

const EmptyPage = lazy(() => import('empty-page'))

const Terms = lazy(() => import('features/users/pages/Terms'))
const PrivacyPolicy = lazy(() => import('features/users/pages/PrivacyPolicy'))
const Dashboard = lazy(() => import('features/dashboards/pages/Dashboard'))

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: <PrivateRoute />,
    children: [
      {
        element: <Main />,
        path: AppRoutes.main
      },
      {
        element: <UserInvite />,
        path: `${AppRoutes.userInvite}/:inviteToken`
      },
      {
        element: <DataRoute />,
        errorElement: <ErrorPage />,
        children: [
          {
            element: <UserAccessRoute roleRequired={[Pto.Workspaces.WorkspaceRole.Contact]} />,
            children: [
              {
                path: `${AppRoutes.workspaces}/:workspaceId/space/:spaceId/onboarding`,
                element: <LearnerOnboardingPage />
              }
            ]
          },
          {
            element: <AppLayout hasSubMenu />,
            errorElement: <ErrorPage />,
            children: [
              {
                element: (
                  <UserAccessRoute
                    roleRequired={[
                      Pto.Workspaces.WorkspaceRole.Contact,
                      Pto.Workspaces.WorkspaceRole.Staff,
                      Pto.Workspaces.WorkspaceRole.Admin
                    ]}
                  />
                ),
                children: [
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId/space/:spaceId`,
                    element: <SpacePage />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId/space/`,
                    element: <EmptyPage />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId`,
                    element: <EmptyPage />
                  },
                  {
                    element: <SettingsLayout />,
                    children: [
                      {
                        path: `${AppRoutes.settings}/:workspaceId/profile-settings`,
                        element: <ProfileSettings />
                      },
                      {
                        path: `${AppRoutes.settings}/:workspaceId/support`,
                        element: <Support />
                      }
                    ]
                  }
                ]
              },
              {
                element: (
                  <UserAccessRoute
                    roleRequired={[Pto.Workspaces.WorkspaceRole.Admin, Pto.Workspaces.WorkspaceRole.Staff]}
                  />
                ),
                children: [
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId/dashboard`,
                    element: <Dashboard />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId${AppRoutes.alerts}`,
                    element: <AlertList />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId${AppRoutes.alerts}/:alertId`,
                    element: <AlertList />
                  },
                  {
                    element: <SettingsLayout />,
                    children: [
                      {
                        path: `/settings/:workspaceId`,
                        element: <EmptyPage />
                      },
                      {
                        path: `/settings/:workspaceId/general`,
                        element: <WorkspaceGeneralSettings />
                      },
                      {
                        path: `/settings/:workspaceId/members`,
                        element: <WorkspaceMembersSettings type="general" />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/general`,
                        element: <SpaceGeneralSettings />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/ai-config`,
                        element: <SpaceAIConfig />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/onboarding-interview`,
                        element: <SpaceOnboardingInterview />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/members`,
                        element: <SpaceMembers />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/members/:memberId`,
                        element: <SpaceMemberProfile />
                      },
                      {
                        path: `/settings/:workspaceId/space/:spaceId/users/:userId`,
                        element: <SpaceMemberProfile />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            element: <AppLayout />,
            errorElement: <ErrorPage />,
            children: [
              {
                element: <UserAccessRoute roleRequired={[Pto.Users.UserRole.SystemAdmin]} />,
                children: [
                  {
                    path: AppRoutes.workspaces,
                    element: <Workspaces />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId/members`,
                    element: <WorkspaceMembersSettings type="system" />
                  },
                  {
                    path: AppRoutes.feedbacks,
                    element: <UserFeedbacks />
                  },
                  {
                    path: AppRoutes.appSettings,
                    element: <AppSettings />
                  },
                  {
                    path: AppRoutes.conversationStartersManagement,
                    element: <ConversationStartersManagement />
                  },
                  {
                    path: AppRoutes.systemAdminDashboard,
                    element: <SystemAdminDashboard />
                  },
                  {
                    path: AppRoutes.productManagement,
                    element: <ProductManagement />
                  },
                  {
                    path: AppRoutes.flows,
                    element: <Flows />
                  },
                  {
                    path: `${AppRoutes.flows}/:flowId`,
                    element: <FlowInfo />
                  },
                  {
                    path: `${AppRoutes.inductionCategories}`,
                    element: <InductionCategories />
                  },
                  {
                    path: `${AppRoutes.productManagement}/set-up`,
                    element: <ProductSetUp />
                  },
                  {
                    path: `${AppRoutes.productManagement}/:productId/set-up`,
                    element: <ProductSetUp />
                  },
                  {
                    path: `${AppRoutes.workspaces}/:workspaceId${AppRoutes.workspaceAllSpaces}`,
                    element: <SystemAdminSpaceManagement />
                  }
                ]
              }
            ]
          },
          {
            element: (
              <UserAccessRoute
                roleRequired={[Pto.Workspaces.WorkspaceRole.Admin, Pto.Workspaces.WorkspaceRole.Staff]}
              />
            ),
            children: [
              {
                path: `${AppRoutes.workspaces}/:workspaceId/set-up`,
                element: <WorkspaceSetup />
              },
              {
                path: `${AppRoutes.workspaces}/:workspaceId/space/set-up`,
                element: <SpaceSetup />
              },
              {
                path: `${AppRoutes.workspaces}/:workspaceId/space/:spaceId/set-up`,
                element: <SpaceSetup />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: AppRoutes.login,
    element: (
      <AnonymousRoute>
        <Login />
      </AnonymousRoute>
    )
  },
  {
    path: AppRoutes.oneTimeLink,
    element: (
      <AnonymousRoute>
        <OneTimeLink />
      </AnonymousRoute>
    )
  },
  {
    path: `${AppRoutes.oneTimeToken}/:oneTimeToken`,
    element: <OneTimeLinkAuth />
  },
  {
    path: AppRoutes.terms,
    element: <Terms />
  },
  {
    path: AppRoutes.policy,
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <NotFound />
  }
])

function App() {
  return (
    <Authenticator.Provider>
      <ThemeProvider>
        <Suspense
          fallback={
            <Flex className="w-screen h-dvh lg:h-screen" justify="center" align="center">
              <Spin spinning={true} size="large" />
            </Flex>
          }
        >
          <RouterProvider
            router={router}
            fallbackElement={
              <Flex className="w-screen h-dvh lg:h-screen" justify="center" align="center">
                <Spin spinning={true} size="large" />
              </Flex>
            }
          />
        </Suspense>
      </ThemeProvider>
    </Authenticator.Provider>
  )
}

export default App
