import { Pto } from '@outcoreaix/pto'
import { RcFile } from 'antd/es/upload'
import { getQueryParamString } from 'utils/get-query-param-string'
import { apiSlice } from './api-splice'

export const workspacesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspaces: builder.query<Pto.Workspaces.List, Pto.Workspaces.WorkspaceListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `workspaces?${queryParams}`,
          method: 'GET'
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs }
        if (newQueryArgs.page) {
          delete newQueryArgs.page
        }
        return newQueryArgs
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Workspaces', id }) as const),
              { type: 'Workspaces' as const, id: 'LIST' }
            ]
          : [{ type: 'Workspaces' as const, id: 'LIST' }]
      }
    }),
    getWorkspacesOptions: builder.query<Pto.Workspaces.WorkspaceOption[], void>({
      query: () => ({
        url: 'workspaces/options',
        method: 'GET'
      }),
      providesTags: ['WorkspacesOptions']
    }),
    getWorkspace: builder.query<Pto.Workspaces.Workspace, number>({
      query: (id) => ({
        url: `workspaces/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Workspaces', id }]
    }),
    getWorkspaceName: builder.query<Pto.Workspaces.WorkspaceName, number>({
      query: (id) => ({
        url: `workspaces/${id}/name`,
        method: 'GET'
      }),
      providesTags: ['Workspaces']
    }),
    addWorkspace: builder.mutation<
      Pto.Workspaces.Workspace,
      Pto.Workspaces.CreateWorkspace & { logo_file: RcFile; kb_file?: RcFile; course_kb_file?: RcFile }
    >({
      query: ({ logo_file, kb_file, course_kb_file, name, address, email, website, colors, subdomain }) => {
        const body = new FormData()
        if (kb_file) body.append('kb_file', kb_file)
        if (course_kb_file) body.append('course_kb_file', course_kb_file)
        body.append('logo_file', logo_file)
        body.append('json', JSON.stringify({ name, address, email, website, subdomain, ...colors }))
        return {
          url: 'workspaces',
          method: 'POST',
          body: body
        }
      },
      invalidatesTags: ['Workspaces', 'WorkspacesOptions']
    }),
    patchWorkspace: builder.mutation<
      Pto.Workspaces.Workspace,
      Pto.Workspaces.UpdateWorkspace & { id: number; kb_file?: RcFile; course_kb_file?: RcFile }
    >({
      query: ({ id, kb_file, course_kb_file, ...data }) => {
        const body = new FormData()
        if (kb_file) body.append('kb_file', kb_file)
        if (course_kb_file) body.append('course_kb_file', course_kb_file)
        body.append('json', JSON.stringify(data))
        return {
          url: `workspaces/${id}`,
          method: 'PATCH',
          body: body
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Workspaces', id }, 'WorkspacesOptions']
    }),
    deleteWorkspace: builder.mutation<void, number>({
      query: (id) => ({
        url: `workspaces/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          workspacesApi.util.updateQueryData('getWorkspaces', {}, (draft) => {
            draft.items = draft.items.filter((workspace) => workspace.id !== id)
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, id) => [
        { type: 'Workspaces', id },
        { type: 'Workspaces', id: 'LIST' },
        { type: 'WorkspacesOptions' }
      ]
    }),
    getWorkspaceMembers: builder.query<
      Pto.Workspaces.ListMemberWithInvite,
      Pto.Workspaces.WorkspaceMembersListQuery & { id: number }
    >({
      query: ({ id, ...params }) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `workspaces/${id}/members/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: [{ type: 'Workspaces' }, 'Members']
    }),
    getWorkspaceMember: builder.query<
      Pto.Workspaces.Member,
      { workspaceId: number; id: number; type: 'users' | 'members' }
    >({
      query: ({ workspaceId, id, type }) => ({
        url: `workspaces/${workspaceId}/${type}/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, { id, type }) =>
        type === 'users' ? [{ type: 'Workspaces', userId: id }, 'Members'] : [{ type: 'Workspaces', id }, 'Members']
    }),
    getWorkspaceMembersForOptions: builder.query<Pto.Meta[], Pto.Workspaces.GetWorkspaceMembersForOptionsQuery>({
      query: ({ workspaceId, searchText, excludeRoles }) => {
        const queryParams = getQueryParamString({ searchText, excludeRoles })
        return {
          url: `workspaces/${workspaceId}/members-for-options/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['WorkspaceMembersOptions']
    }),
    patchWorkspaceKnowledgeBase: builder.mutation<void, { file: RcFile; id: number }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)

        return {
          url: `workspaces/${id}/knowledge-base`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    }),
    patchWorkspaceLogo: builder.mutation<void, { file: RcFile; id: number; name?: string }>({
      query: ({ id, file, name }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)
        if (name) {
          body.append('workspaceName', name)
        }

        return {
          url: `workspaces/${id}/logo`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    }),
    patchWorkspaceOnboarding: builder.mutation<void, { file: RcFile; id: number } & Pto.Workspaces.OnboardWorkspace>({
      query: ({ id, file, name }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)
        if (name) {
          body.append('workspaceName', name)
        }

        return {
          url: `workspaces/${id}/onboarding`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    }),
    registerSubdomain: builder.mutation<{ url: string | null }, { workspaceId: number; subdomain: string }>({
      query: ({ workspaceId, subdomain }) => ({
        url: `workspaces/${workspaceId}/register-subdomain`,
        method: 'POST',
        body: { subdomain }
      }),
      invalidatesTags: ['Workspaces']
    })
  })
})

export const {
  useGetWorkspacesQuery,
  useLazyGetWorkspacesQuery,
  useGetWorkspacesOptionsQuery,
  useGetWorkspaceQuery,
  useGetWorkspaceNameQuery,
  useGetWorkspaceMembersForOptionsQuery,
  useLazyGetWorkspaceQuery,
  useAddWorkspaceMutation,
  usePatchWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useGetWorkspaceMembersQuery,
  useGetWorkspaceMemberQuery,
  usePatchWorkspaceKnowledgeBaseMutation,
  usePatchWorkspaceLogoMutation,
  usePatchWorkspaceOnboardingMutation,
  useRegisterSubdomainMutation
} = workspacesApi
