import { ConfigProvider } from 'antd'
import { ThemeAggregatedColors, useTheme } from 'components/ThemeContext'
import { FC, PropsWithChildren } from 'react'

const opacityString = '80'

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useTheme()
  const { primaryColor, secondaryColor } = theme as ThemeAggregatedColors

  const primaryHex = primaryColor.toHexString()
  const secondaryHex = secondaryColor.toHexString()

  const secondaryHexWithOpacity = `${secondaryHex}${opacityString}`

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryHex,
          colorInfo: primaryHex,
          colorSuccess: '#12b76a',
          colorWarning: '#f79009',
          colorError: '#f04438',
          colorTextBase: primaryColor ? 'black' : '#101828',
          borderRadius: 8,
          fontSize: 16
        },
        components: {
          Layout: {
            bodyBg: secondaryHex || 'var(--background)',
            headerBg: secondaryHex || 'var(--background)'
          },
          Table: {
            headerBg: secondaryHex || '#EBF6FF',
            borderColor: secondaryHexWithOpacity || 'var(--colorLightBorder)',
            fontWeightStrong: 500,
            boxShadowSecondary: 'none',
            cellFontSize: 14
          },
          Form: {
            labelFontSize: 14,
            fontSize: 14,
            lineHeight: 1,
            itemMarginBottom: 18,
            controlHeightSM: 18
          },
          Button: {
            fontWeight: 600,
            paddingInline: 11,
            paddingBlockLG: 7,
            controlHeightLG: 42
          },
          Drawer: {
            paddingLG: 16
          },
          Menu: {
            itemHoverBg: secondaryHexWithOpacity,
            itemActiveBg: secondaryHex,
            itemSelectedBg: secondaryHex,
            subMenuItemBg: '#ffffff',
            colorIconHover: secondaryHexWithOpacity
          },
          Slider: {
            handleSize: 11,
            dotSize: 14
          },
          Input: {
            paddingInline: 14,
            paddingBlock: 8,
            controlHeight: 42
          },
          Select: {
            controlHeightLG: 42
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default ThemeProvider
