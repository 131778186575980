import axios from 'app/axios'
import { AggregationColor } from 'antd/es/color-picker/color'
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'

export type ThemeHexColors = {
  primaryColor: string
  secondaryColor: string
}

export type ThemeAggregatedColors = {
  primaryColor: AggregationColor
  secondaryColor: AggregationColor
}

export type ThemeColors = {
  primaryColor: string
  secondaryColor: string
}

export type ThemeContextType = {
  theme: ThemeAggregatedColors
  themeColors: ThemeColors
  workspaceLogo: string | null
  isLoading: boolean
  setThemeColors: (colors: ThemeColors) => void
  setTheme: (theme: ThemeAggregatedColors) => void
  setWorkspaceLogo: (logo: string | null) => void
}

const defaultTheme: ThemeHexColors = {
  primaryColor: '#0582ef',
  secondaryColor: '#EBF6FF'
}

const defaultAggregationTheme = {
  primaryColor: new AggregationColor(defaultTheme.primaryColor),
  secondaryColor: new AggregationColor(defaultTheme.secondaryColor)
}

const opacityString = '80'

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a CustomThemeProvider')
  }
  return context
}

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeAggregatedColors>(defaultAggregationTheme)
  const [workspaceLogo, setWorkspaceLogo] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchThemeColors = async () => {
      try {
        setIsLoading(true)

        const hostname = window.location.hostname
        const subdomain = hostname.split('.')[0]

        if (hostname === 'localhost') {
          return
        }

        const response = await axios.get(`workspaces/theme/${subdomain}`)
        const { primaryColor, secondaryColor, logoUrl } = response.data

        if (primaryColor && secondaryColor) {
          setThemeColors({ primaryColor, secondaryColor })
        } else {
          setThemeColors(defaultTheme)
        }

        if (logoUrl) {
          setWorkspaceLogo(logoUrl)
        } else {
          setWorkspaceLogo(null)
        }
      } catch (error) {
        setThemeColors(defaultTheme)
        setWorkspaceLogo(null)
      } finally {
        setIsLoading(false)
      }
    }

    fetchThemeColors()
  }, [])

  const setThemeColors = (colors: ThemeColors) => {
    if (colors.primaryColor === '' && colors.secondaryColor === '') colors = defaultTheme
    const newTheme = {
      primaryColor: new AggregationColor(colors.primaryColor),
      secondaryColor: new AggregationColor(colors.secondaryColor)
    }
    setTheme(newTheme)
    changeRootColor('--colorPrimary', colors.primaryColor)
    changeRootColor('--colorSecondary', colors.secondaryColor)
    changeRootColor('--colorSecondaryWithOpacity', `${colors.secondaryColor}${opacityString}`)
  }

  const changeRootColor = (property: string = '--colorPrimary', newColor: string) => {
    document.documentElement.style.setProperty(property, newColor)
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeColors: { primaryColor: theme.primaryColor.toString(), secondaryColor: theme.secondaryColor.toString() },
        setThemeColors,
        setTheme,
        workspaceLogo,
        setWorkspaceLogo,
        isLoading
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
