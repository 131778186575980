import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Pto } from '@outcoreaix/pto'
import { Avatar, Button, Divider, Flex, Tooltip, Typography } from 'antd'
import { AppRoutes } from 'app/app-routes'
import { authStorage } from 'app/auth-storage'
import clsx from 'clsx'
import ConditionalWrapper from 'components/ConditionalWrapper'
import { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

type Props = {
  onMenuItemClick?: () => void
  collapsed?: boolean
  workspace?: Pto.Workspaces.WorkspaceOption
}
const UserMenuItem: FC<Props> = ({ collapsed, workspace, onMenuItemClick }) => {
  const user = authStorage.user
  const params = useParams()
  const navigate = useNavigate()
  return (
    <div className="max-xl:pb-4 md:sticky xl:block bottom-0 bg-white">
      <Divider />
      <Flex gap={12} align="center" className={clsx('px-1', [collapsed && 'flex-col'])}>
        <ConditionalWrapper
          Wrapper={Link}
          wrapperProps={{
            to: `${AppRoutes.settings}/${params.workspaceId}/profile-settings`,
            onClick: onMenuItemClick
          }}
          condition={!user?.roles.includes(Pto.Users.UserRole.SystemAdmin)}
        >
          <Tooltip
            title={
              <Flex vertical justify="center">
                <Typography.Paragraph className="font-medium !mb-0 text-sm">
                  {user?.firstName} {user?.lastName}
                </Typography.Paragraph>
                <Typography.Text className="text-sm">{user?.email}</Typography.Text>
              </Flex>
            }
            color="#fff"
          >
            <Flex gap={12} align="center">
              <Avatar
                size={40}
                className="bg-transparent hover:bg-secondaryWithOpacity text-primary !w-[42px]"
                icon={<UserOutlined />}
              />
              <Flex vertical justify="center" className={clsx('max-w-56 lg:max-w-36', [collapsed && 'hidden'])}>
                <Typography.Paragraph className="font-medium !mb-0 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                  {user?.firstName} {user?.lastName}
                </Typography.Paragraph>
                <Typography.Text className="text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                  {user?.email}
                </Typography.Text>
              </Flex>
            </Flex>
          </Tooltip>
        </ConditionalWrapper>
        {workspace && !!workspace.currentMember && (
          <Tooltip title="Settings" placement="right">
            <Button
              type="text"
              className="items-center flex h-10 ml-auto hover:!bg-secondaryWithOpacity"
              onClick={() =>
                navigate(
                  `/settings/${workspace.id}/${workspace?.currentMember?.role === Pto.Workspaces.WorkspaceRole.Contact ? 'profile-settings' : 'general'}`
                )
              }
            >
              <SettingOutlined className="[&>svg]:w-[18px] [&>svg]:h-[18px] -ml-px" />
            </Button>
          </Tooltip>
        )}
        <ConditionalWrapper
          Wrapper={Tooltip}
          wrapperProps={{ title: 'Log out', placement: 'right' }}
          condition={!!collapsed}
        >
          <Button
            type="text"
            className="items-center flex h-10 ml-auto hover:!bg-secondaryWithOpacity"
            onClick={() => authStorage.signOut()}
          >
            <LogoutOutlined className="[&>svg]:w-[18px] [&>svg]:h-[18px]" />
          </Button>
        </ConditionalWrapper>
      </Flex>
    </div>
  )
}

export default UserMenuItem
